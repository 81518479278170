import loadable from '@loadable/component';
import { redirect } from 'saddlebag-browser';

import BpkGraphicPromo, {
  TEXT_ALIGN,
} from '@skyscanner/backpack-web/bpk-component-graphic-promotion';

import { ACTION_TYPE } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import FooterBlurb from '../common/FooterBlurb';
import { useMBDContext, buildMBDParam } from '../common/MBD/useMBDContext';

import type { GraphicPromotionProps } from 'common-types/types/GraphicPromotionProps';

import STYLES from './GraphicPromotion.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

type MBDType = {
  componentName: string;
  vertical: string;
};

const gradient = `linear-gradient(90deg, rgba(22, 22, 22, 0.45) 0%, rgba(22, 22, 22, 0) 100%)`;

const GraphicPromotion = (props: GraphicPromotionProps & MBDType) => {
  const {
    buttonText,
    buttonUri,
    componentName,
    disclaimer,
    hasOverlay = true,
    headline,
    imageAlign = 'center',
    imageUri,
    subheading,
    textAlign = TEXT_ALIGN.start,
    vertical,
  } = props;
  const backgroundImage = hasOverlay
    ? `${gradient}, url(${imageUri})`
    : `url(${imageUri})`;

  return (
    <div
      className={STYLES.GraphicPromotion}
      data-tracking-element-id={componentName}
      data-tracking-common-params={buildMBDParam(componentName, vertical, {
        buttonUri,
      })}
    >
      <BpkGraphicPromo
        buttonText={buttonText}
        headline={headline}
        subheading={subheading}
        onClick={() => redirect(buttonUri)}
        style={{
          backgroundImage,
          backgroundPositionX: imageAlign,
        }}
        textAlign={textAlign}
        invertVertically
      />
      {disclaimer && (
        <div className={STYLES.GraphicPromotion__disclaimer}>
          <FooterBlurb disclaimerCopy={disclaimer} />
        </div>
      )}
    </div>
  );
};

export default (props: GraphicPromotionProps) => {
  const { rawComponentName, vertical } = useMBDContext();

  return (
    <IntersectionObserverWrapper
      onElementSeen={() =>
        logMiniEventHelper({
          action_type: ACTION_TYPE.COMPONENT_VISIBLE,
          component_name: rawComponentName.toUpperCase(),
        })
      }
    >
      <GraphicPromotion
        componentName={rawComponentName}
        vertical={vertical}
        {...props}
      />
    </IntersectionObserverWrapper>
  );
};
